import { getAction, postAction } from "@/api/manage";
import { ElMessage } from "element-plus";
const req = {
	getAction,
	postAction,
};
export default {
	data() {
		return {
			requestWay: "postAction",
			autoRequest: true, // 自动请求
			loading: false,
			head: [],
			tableData: [],
			pageNo: 1,
			pageSize: 10,
			pageSizeArr: [10, 20, 30, 50, 100],
			total: 0,
		};
	},
	created() {
		if (this.autoRequest) {
			if (this.url && this.url.list) {
				this.loadData(1);
			}
		}
	},
	methods: {
		loadData(count) {
			let params = this.getParams();
			params.page = count;
			params.limit = this.pageSize;
			this.loading = true;
			setTimeout(() => {
				req[this.requestWay](this.url.list, params)
					.then((res) => {
						if (res.code == 200) {
							let obj = res.data;
							obj.content.forEach((item) => {
								item._loading_btn = [];
								item._loading_com = [];
								item.data.forEach((val) => {
									if (Array.isArray(val)) {
										let arr = [];
										val.forEach(() => {
											arr.push(false);
										});
										item._loading_btn.push(arr);
									} else {
										item._loading_btn.push("");
									}
								});
								obj.title.forEach((val) => {
									if (val.edit) {
										item._loading_com.push(false);
									} else {
										item._loading_com.push("");
									}
								});
							});
							this.handleData(obj);
							this.pageNo = count;
							if ("count" in res) {
								this.total = res.count;
							}
						} else {
							ElMessage.error(res.message || res.msg);
						}
					})
					.finally(() => {
						this.loading = false;
					});
			}, 300);
		},
		handleData(data) {
			this.head = data.title;
			this.tableData = data.content;
		},
		getParams() {
			let params = {};
			if (this.params) {
				Object.entries(this.params).forEach((item) => {
					params[item[0]] = item[1];
				});
			}
			return params;
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.loadData(this.pageNo);
		},
		// 分页
		handleCurrentChange(val) {
			this.pageNo = val;
			this.loadData(this.pageNo);
		},
	},
};

import { axios } from "@/utils/request";

//post
export function postAction(url, parameter) {
	return axios({
		url: url,
		method: "POST",
		data: parameter,
	});
}
//get
export function getAction(url, params, config) {
	return axios({
		url,
		method: "GET",
		params,
		...config,
	});
}

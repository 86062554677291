<template>
	<div class="common-layout" :style="{ 'background-color': !pageFlag ? '#0494f5' : 'transparent' }">
		<div
			v-if="!pageFlag"
			class="mask-dialog"
			v-loading="loading"
			element-loading-text="正在进入。。。"
			element-loading-background="#0494f5"
		></div>
		<el-container v-if="pageFlag">
			<CommonAside class="aside"></CommonAside>
			<el-container class="container">
				<el-header height="96" style="padding: 0">
					<CommonHeader></CommonHeader>
					<CommonTab></CommonTab>
				</el-header>
				<el-main class="main">
					<router-view></router-view>
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>
<script>
import { getAction } from "@/api/manage";
import { ElMessage } from "element-plus";

import { defineComponent } from "vue";
import CommonHeader from "@/components/CommonHeader.vue";
import CommonAside from "@/components/CommonAside.vue";
import CommonTab from "@/components/CommonTab.vue";

export default defineComponent({
	components: {
		CommonHeader,
		CommonAside,
		CommonTab,
	},
	data() {
		return {
			loading: false, // 动画
			msgFlag: false, // 提示
			pageFlag: false, // 页面
		};
	},
	created() {
		this.loading = true;
		setTimeout(() => {
			this.getMenuList();
		}, 500);
	},
	methods: {
		getMenuList() {
			getAction("jc/right/menu_list").then((res) => {
				if (res.list) {
					this.$store.dispatch("changeMenu", [
						{
							id: "00001",
							path: "/",
							name: "home",
							label: "首页",
							icon: "HomeFilled",
							url: "/",
						},
						...res.list,
					]);
					if (this.msgFlag) {
						ElMessage.closeAll();
					}
					this.loading = false;
					this.pageFlag = true;
				} else {
					if (!this.msgFlag) {
						this.msgFlag = true;
						ElMessage({
							message: "菜单获取失败，正在重新获取，请稍等",
							type: "error",
							duration: 0,
						});
					}
					setTimeout(() => {
						this.getMenuList();
					}, 1000);
				}
			});
		},
	},
});
</script>
<style lang="less" scoped>
.common-layout {
	height: 100vh;
	width: 100%;
}
.mask-dialog {
	height: 100vh;
	width: 100%;
}
.aside {
	height: 100vh;
}
.aside::-webkit-scrollbar {
	width: 0;
}
.container {
	height: 100vh;
}
.main {
	height: calc(100vh - 96px);
	padding: 3px 20px 40px;
	box-sizing: border-box;
}
.main::-webkit-scrollbar {
	width: 8px;
}
.main::-webkit-scrollbar-thumb {
	background-color: #69c3ff;
	border-radius: 5px;
}
.main::-webkit-scrollbar-track {
	background-color: #fff;
}
/deep/ .mask-dialog .el-loading-spinner {
	transform: translateY(-50%);
}
/deep/ .mask-dialog .el-loading-spinner .path {
	stroke: white;
}
/deep/ .mask-dialog .el-loading-spinner .el-loading-text {
	color: white;
}
</style>

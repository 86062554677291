import { createStore } from "vuex";
import createPersistedstate from "vuex-persistedstate";
import { ElMessage } from "element-plus";
import router from "@/router";
let source = () => {
	return {
		isCollapse: true,
		currentMenu: null,
		menuList: [],
		tabsList: [
			{
				path: "/",
				name: "home",
				label: "首页",
				icon: "home",
			},
		],
	};
};
let state = Object.assign({}, source());
export default createStore({
	state,
	mutations: {
		updateIsCollapse(state, payload) {
			state.isCollapse = !state.isCollapse;
		},
		selectMenu(state, val) {
			// console.log("start");
			let fromName = router.currentRoute.value.name;
			if (val.name == "home") {
				state.currentMenu = null;
			} else {
				state.currentMenu = val;
				let fromIndex = state.tabsList.findIndex((item) => item.name == fromName);
				let targetIndex = state.tabsList.findIndex((item) => item.name == val.name);
				if (targetIndex != -1) {
					if (fromName == "home") {
						return;
					}
					state.tabsList.splice(targetIndex, 1);
					state.tabsList.splice(fromIndex > targetIndex ? fromIndex : fromIndex + 1, 0, val);
				} else {
					state.tabsList.splice(fromIndex + 1, 0, val);
				}
			}
			// console.log("end");
		},
		closeTab(state, val) {
			let res = state.tabsList.findIndex((item) => item.name == val.name);
			state.tabsList.splice(res, 1);
		},
		changeMenu(state, list) {
			state.menuList = list;
		},
		resetState(state) {
			Object.assign(state, source());
		},
		clearTag(state, name) {
			let arr = [];
			state.tabsList.forEach((item) => {
				if (item.name == "home" || item.name == name) {
					arr.push(item);
				}
			});
			state.tabsList = arr;
			ElMessage.success("导航栏已清空");
		},
	},
	actions: {
		changeMenu(e, list) {
			e.commit("changeMenu", list);
		},
		resetState(e) {
			e.commit("resetState");
		},
		clearTag(e, name) {
			e.commit("clearTag", name);
		},
	},
	modules: {},
	plugins: [
		createPersistedstate({
			key: "usermessage",
		}),
	],
});

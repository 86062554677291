<template>
	<div class="wrap">
		<div class="left">
			<el-tree
				style="width: 80%; height: 90%; user-select: none"
				:loading="treeLoading"
				:data="treeData"
				:props="{ label: 'name' }"
				show-checkbox
				default-expand-all
				check-strictly
				check-on-click-node
				:expand-on-click-node="false"
				@check="treeCheck"
			/>
		</div>
		<div class="right">
			<div class="flexEnd">
				<el-button type="primary" style="margin: 0 0 20px" @click="submit" :loading="submitLoading">提交分解</el-button>
			</div>
			<m-table ref="mTable" :request="requestEdit"></m-table>
		</div>
	</div>
</template>

<script>
import { getAction, postAction } from "@/api/manage";
import { ElMessage } from "element-plus";
export default {
	name: "cutBox",
	data() {
		return {
			requestEdit: {
				table: {
					requestWay: "postAction",
					url: "ys/task/break/select",
				},
			},
			treeLoading: false,
			submitLoading: false,
			treeData: [],
		};
	},
	methods: {
		init() {
			this.treeLoading = true;
			getAction("jc/dept/getTree")
				.then((res) => {
					if (res.code == 200) {
						this.treeData = res.data;
					} else {
						ElMessage.error(res.message || res.msg);
					}
				})
				.finally(() => {
					this.treeLoading = false;
				});
		},
		treeCheck(a, b) {
			let str = "";
			b.checkedNodes.forEach((item) => {
				str += item.id + ",";
			});
			str = str.substr(0, str.length - 1);
			this.$refs.mTable.init({ deptids: str }, [
				{
					edit: 3,
					type: "select",
					url: "jc/dept/getDeptMember",
					method: "getAction",
					params: [
						{
							key: "deptid",
							source: "deptid",
						},
					],
				},
			]);
		},
		submit() {
			let arr = this.$refs.mTable.getTableData();
			if (arr.length == 0) {
				ElMessage.error("请选择部门确定要分解的数据");
				return;
			}
			this.submitLoading = true;
			let data = [];
			arr.forEach((item) => {
				data.push(item.data);
			});
			let params = {
				data,
			};
			postAction("ys/task/break/break", params)
				.then((res) => {
					if (res.code == 200) {
						ElMessage({
							message: res.message || res.msg,
							type: "success",
						});
						this.$emit("CutOk");
					} else {
						ElMessage.error(res.message || res.msg);
					}
				})
				.finally(() => {
					this.submitLoading = false;
				});
		},
	},
};
</script>

<style scoped>
::-webkit-scrollbar {
	width: 5px;
}
::-webkit-scrollbar-thumb {
	background-color: #a7bbdd;
	border-radius: 4px;
}
::-webkit-scrollbar-track {
	background-color: #fff;
}
.wrap {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	height: 70vh;
	overflow: auto;
	padding: 10px 0;
	box-sizing: border-box;
}
.left {
	width: 20%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.right {
	width: 80%;
	height: 100%;
}
.flexEnd {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
</style>

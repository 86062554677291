<template>
	<div class="wrap">
		<div class="nav-icon" @click="clickPrev" style="margin: 0 15px 0 0">
			<el-icon><Back /></el-icon>
		</div>
		<div class="cen-box">
			<div class="tags" ref="tagscroll">
				<el-tag
					v-for="(tag, index) in tags"
					:key="tag.name"
					:closable="tag.name !== 'home'"
					:effect="$route.name === tag.name ? 'light' : 'plain'"
					@click="changMenu(tag)"
					@close="handleClose(tag, index)"
					color="#fff"
					class="tag"
				>
					<span class="isShow" v-if="$route.name === tag.name"></span>{{ tag.label }}
				</el-tag>
			</div>
		</div>
		<div class="nav-icon" style="margin: 0 0 0 15px">
			<el-popconfirm title="操作确认" @confirm="clearTag">
				<template #reference>
					<div>
						<el-tooltip effect="dark" content="清空导航栏，仅保留当前页和首页" placement="left">
							<el-icon><Delete /></el-icon>
						</el-tooltip>
					</div>
				</template>
			</el-popconfirm>
		</div>
		<div class="nav-icon" @click="clickNext" style="margin: 0 0 0 15px">
			<el-icon><Right /></el-icon>
		</div>
	</div>
</template>
<script>
import router from "@/router";
import { getCurrentInstance, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
export default {
	setup() {
		const store = useStore();
		const route = useRoute();
		const { proxy } = getCurrentInstance();
		const tags = computed(() => {
			return store.state.tabsList;
		});
		const clickPrev = () => {
			proxy.$refs.tagscroll.scrollLeft -= 111;
		};
		const clickNext = () => {
			proxy.$refs.tagscroll.scrollLeft += 111;
		};
		const changMenu = (item) => {
			if (item.name == route.name) {
				return;
			}
			router.push({
				path: item.path,
				query: item.query ? item.query : "",
			});
		};
		const handleClose = (tag, index) => {
			store.commit("closeTab", tag);
			if (tag.name != route.name) {
				return;
			}
			let temp = tags.value;
			router.push({
				path: temp[index - 1].path,
				query: temp[index - 1].query ? temp[index - 1].query : "",
			});
		};
		const clearTag = () => {
			store.dispatch("clearTag", route.name);
		};
		return {
			tags,
			changMenu,
			handleClose,
			clickPrev,
			clickNext,
			clearTag,
		};
	},
};
</script>
<style lang="less" scoped>
* {
	user-select: none;
}
.wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 0 20px;
	box-sizing: border-box;
}
.cen-box {
	flex: 1;
	overflow: hidden;
}
.tags {
	padding: 14px 0;
	box-sizing: border-box;
	position: relative;
	z-index: 5;
	white-space: nowrap;
	overflow: auto;
	.el-tag {
		margin-right: 15px;
		cursor: pointer;
		border: none;
		color: #666;
	}
	.el-tag:nth-child(1) {
		position: sticky;
		z-index: 10;
		left: 0;
	}
}
.isShow {
	display: inline-block;
	width: 10px;
	height: 10px;
	background-color: #1269ff;
	margin-right: 3px;
	border-radius: 50%;
}
::-webkit-scrollbar {
	height: 0;
}
.nav-icon {
	width: 24px;
	height: 24px;
	background-color: #ffffffcc;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
</style>

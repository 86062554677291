<template>
	<el-card :style="cardStyle" :body-style="{ height: '100%' }" v-loading="loading">
		<el-table :data="tableData" :height="`calc(100% - ${total > 0 ? 52 : 0}px)`" :border="true" @selection-change="handleSelectionChange">
			<el-table-column v-if="checkGroup" type="selection" width="55" />
			<el-table-column align="left" v-for="(item, index) in head" :key="index" :label="item.name" v-bind="tableBind(item, index)">
				<template #default="scope">
					<div v-if="item.button" style="display: flex; flex-wrap: wrap">
						<div style="padding: 0 5px 5px" v-for="(val, pri) in scope.row.data[index]" :key="pri">
							<template v-if="val == '删除'">
								<el-popconfirm
									title="您确定要删除吗？"
									:hide-after="10"
									@confirm="
										handleButton({
											val,
											item: scope.row,
											index,
											pri,
											scope,
										})
									"
								>
									<template #reference>
										<el-button type="danger" size="small" :loading="scope.row._loading_btn[index][pri]">{{ val }}</el-button>
									</template>
								</el-popconfirm>
							</template>
							<template v-else-if="btnAll.indexOf(val) != -1">
								<el-button
									:type="val == '保存' ? 'warning' : 'primary'"
									size="small"
									:loading="scope.row._loading_btn[index][pri]"
									@click="
										handleButton({
											val,
											item: scope.row,
											index,
											pri,
											scope,
										})
									"
									>{{ val }}</el-button
								>
								<el-button
									v-if="val == '保存'"
									type="info"
									size="small"
									@click="
										handleButton({
											val: '取消',
											item: scope.row,
											index,
											pri,
											scope,
										})
									"
									>取消</el-button
								>
							</template>
							<template v-else>
								<el-popconfirm
									:title="'您确定要' + val + '吗？'"
									:hide-after="10"
									@confirm="
										handleButton({
											val,
											item: scope.row,
											index,
											pri,
											scope,
										})
									"
								>
									<template #reference>
										<el-button type="primary" size="small" :loading="scope.row._loading_btn[index][pri]">{{ val }}</el-button>
									</template>
								</el-popconfirm>
							</template>
						</div>
					</div>
					<div v-else>
						<div v-if="!item.edit">
							<div
								v-if="
									(currentPageName == 'dwsh' || currentPageName == 'dwpf') &&
									index > 1 &&
									head[index - 1].edit &&
									head[index - 1].edit == 'input_money'
								"
							>
								{{ parseFloat(scope.row.data[index - 2]) + parseFloat(scope.row.data[index - 1]) }}
							</div>
							<div v-else>
								{{ scope.row.data[index] }}
							</div>
						</div>
						<div v-else>
							<div v-if="item.edit == 'switch' || item.edit == 'tree'">
								<el-switch
									v-if="item.edit == 'switch'"
									size="small"
									:loading="scope.row._loading_com[index]"
									:model-value="scope.row.data[index]"
									@change="
										switchBefore($event, {
											str: item.edit,
											item: scope.row,
											index,
										})
									"
								/>
								<template v-if="item.edit == 'tree'">
									<el-tag style="margin: 0 6px 6px 0" v-for="(val, key) in scope.row.data[index].split(',')" :key="key">
										{{ val }}
									</el-tag>
								</template>
							</div>
							<div v-else>
								<div v-if="scope.row.editable">
									<el-input
										style="text-align: center"
										v-if="item.edit == 'input'"
										type="text"
										v-model="scope.row.data[index]"
										size="small"
									/>
									<el-input-number
										v-if="item.edit == 'input_number' || item.edit == 'input_money'"
										v-model="scope.row.data[index]"
										:step="1000"
										:precision="item.edit == 'input_number' ? 0 : 2"
										size="small"
									/>
									<el-select v-if="item.edit == 'select'" v-model="scope.row.data[index]" placeholder="请选择" size="small">
										<el-option
											v-for="item in scope.row._select[index]"
											:key="item.value"
											:label="item.label"
											:value="item.value"
										/>
									</el-select>
									<el-date-picker
										v-if="item.edit == 'datepick'"
										v-model="scope.row.data[index]"
										style="width: 100%"
										:editable="false"
										type="date"
										placeholder="选择时间"
										value-format="YYYY-MM-DD"
										size="small"
										:clearable="false"
									/>
								</div>
								<div v-else>
									{{ scope.row.data[index] }}
								</div>
							</div>
						</div>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<div class="bot" v-if="total > 0">
			<el-pagination
				layout="total, sizes, prev, pager, next, jumper"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="pageNo"
				:page-size="pageSize"
				:page-sizes="pageSizeArr"
				:total="total"
			>
			</el-pagination>
		</div>
		<i-dialog ref="iDialog" :loading="cutLoading" @cancel="cutLoading = false" width="80%" top="10vh" title="分解">
			<cut-box ref="cutBox" @cutOk="cutOk"></cut-box>
		</i-dialog>
	</el-card>
</template>

<script>
import { getAction, postAction } from "@/api/manage";
import { ElMessage } from "element-plus";
import tableList from "@/mixins/list.js";
import iDialog from "@/components/form/dialog.vue";
import store from "@/store";
const req = {
	getAction,
	postAction,
};
export default {
	name: "mTable",
	mixins: [tableList],
	components: {
		iDialog,
	},
	computed: {
		currentPageName() {
			return this.$route.name;
		},
	},
	props: {
		request: {
			type: Object,
			default: () => {
				return {};
			},
		},
		checkGroup: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			autoRequest: false,
			url: {},
			params: {},
			cutLoading: false,
			currentItem: {},
			btnAll: ["编辑", "保存", "取消", "拆分", "分解", "明细", "用户", "部门", "表单定义", "转他人处理", "日志", "审核进度", "提交"],
			tableDataConReq: [],
			tableBind(item, index) {
				let obj = {};
				if (item.button) {
					obj.fixed = "right";
					if (this.tableData.length > 0 && this.tableData[0].data[index].length > 0) {
						let width = 0;
						let count = 0;
						this.tableData[0].data[index].forEach((item) => {
							if (item == "编辑" || item == "保存") {
								count = 60;
							}
							width += 34 + item.length * 12;
						});
						width += 25 + count;
						obj.width = width;
					} else {
						obj.width = 150;
					}
				} else {
					obj["min-width"] = 150;
					if (index == 0) {
						obj.fixed = "left";
					}
				}
				return obj;
			},
			cardStyle: {
				height: "50%",
			},
		};
	},
	methods: {
		// val事名 item行据 index列引 pri钮引
		handleButton(options) {
			let fn = {
				删除: "listRemove",
				编辑: "listEdit",
				保存: "listSave",
				取消: "listCancel",
				拆分: "listUnpack",
				分解: "listCut",
				明细: "listDetail",
				用户: "listDetail",
				部门: "logLink",
				表单定义: "listDetail",
				转他人处理: "listChangePeople",
				日志: "logLink",
				审核进度: "logLink",
				提交: "listSubmit",
			};
			if (!this[fn[options.val]]) {
				this.otherButton(options);
			} else {
				this[fn[options.val]](options);
			}
		},
		// 其余
		otherButton(options) {
			let params = { ...options.item.button[options.pri] };
			if (this.request.other.params) {
				let temp = [];
				this.head.forEach((item, index) => {
					if (item.edit) {
						temp.push(index);
					}
				});
				this.request.other.params.forEach((item) => {
					if (item.type == "id") {
						params[item.key] = options.item.id;
					}
					if (item.type == "edit") {
						temp.forEach((val, key) => {
							params[item.key[key]] = options.item.data[val];
						});
					}
				});
			}
			// options.item._loading_btn[options.index][options.pri] = true;
			req[this.request.other.requestWay](this.request.other.url, params)
				.then((res) => {
					if (res.code == 200) {
						ElMessage({
							message: res.message || res.msg,
							type: "success",
						});
						this.tableData.splice(options.scope.$index, 1);
						if (this.total > 0) {
							this.total -= 1;
						}
						// this.loadData(1); // 其余
					} else {
						ElMessage.error(res.message || res.msg);
					}
				})
				.finally(() => {
					// options.item._loading_btn[options.index][options.pri] = false;
				});
		},
		// 提交
		listSubmit(options) {
			let params = {};
			if (this.request[options.val].params) {
				this.request[options.val].params.forEach((item) => {
					if (item.source == "inside") {
						if (item.type == "id") {
							params[item.key] = options.item.id;
						}
					}
				});
			} else {
				params.id = options.item.id;
			}
			// options.item._loading_btn[options.index][options.pri] = true;
			req[this.request[options.val].requestWay](this.request[options.val].url, params)
				.then((res) => {
					if (res.code == 200) {
						ElMessage({
							message: res.message || res.msg,
							type: "success",
						});
						this.tableData.splice(options.scope.$index, 1);
						if (this.total > 0) {
							this.total -= 1;
						}
						// this.loadData(1); // 提交
					} else {
						ElMessage.error(res.message || res.msg);
					}
				})
				.finally(() => {
					// options.item._loading_btn[options.index][options.pri] = false;
				});
		},
		// 转他人处理
		listChangePeople(options) {
			this.$emit("changePeople", options);
		},
		// 删除
		listRemove(options) {
			let params = {};
			if (this.request[options.val].params) {
				this.request[options.val].params.forEach((item) => {
					if (item.source == "inside") {
						// 行引
						if (item.type == "index") {
							params[item.key] = options.scope.$index;
						}
						// 列引值
						if (item.type == "col") {
							if (item.lSplit) {
								params[item.key] = options.item.data[item.col].split(item.lSplit)[0];
							} else {
								params[item.key] = options.item.data[item.col];
							}
						}
					} else {
						params = Object.assign({}, params, item.getValue());
					}
				});
			} else {
				params.id = options.item.id;
			}
			// options.item._loading_btn[options.index][options.pri] = true;
			req[this.request[options.val].requestWay](this.request[options.val].url, params)
				.then((res) => {
					if (res.code == 200) {
						ElMessage({
							message: res.message || res.msg,
							type: "success",
						});
						this.tableData.splice(options.scope.$index, 1);
						if (this.total > 0) {
							this.total -= 1;
						}
						// this.loadData(1); // 删除
					} else {
						ElMessage.error(res.message || res.msg);
					}
				})
				.finally(() => {
					// options.item._loading_btn[options.index][options.pri] = false;
				});
		},
		// 编辑
		listEdit(options) {
			if (this.$route.name == "qxpz") {
				this.$emit("spEdit", options.item);
				return;
			}
			options.item.editable = true;
			options.item.data[options.index][options.pri] = "保存";
		},
		// 取消
		listCancel(options) {
			options.item.editable = false;
			options.item.data[options.index][options.pri] = "编辑";
		},
		// 保存
		listSave(options) {
			let params = {};
			if (this.request[options.val].ids) {
				Object.assign(params, this.request[options.val].ids());
			} else {
				params.id = options.item.id;
			}
			// 行引
			if (this.request[options.val].index) {
				params[this.request[options.val].index] = options.scope.$index;
			}
			let temp = [];
			this.head.forEach((item, index) => {
				if (item.edit && item.edit != "switch") {
					temp.push(options.item.data[index]);
				}
			});
			temp.forEach((item, index) => {
				params[this.request[options.val].params[index]] = item;
			});
			// options.item._loading_btn[options.index][options.pri] = true;
			req[this.request[options.val].requestWay](this.request[options.val].url, params)
				.then((res) => {
					if (res.code == 200) {
						ElMessage({
							message: res.message || res.msg,
							type: "success",
						});
						options.item.editable = false;
						this.loadData(this.pageNo); // 保存
					} else {
						ElMessage.error(res.message || res.msg);
					}
				})
				.finally(() => {
					// options.item._loading_btn[options.index][options.pri] = false;
				});
		},
		// 拆分
		listUnpack(options) {
			let params = {};
			this.request[options.val].params.forEach((item) => {
				if (item.source == "inside") {
					params[item.key] = options.item[item.value];
				} else {
					params[item.key] = item.getValue();
				}
			});
			// options.item._loading_btn[options.index][options.pri] = true;
			req[this.request[options.val].requestWay](this.request[options.val].url, params)
				.then((res) => {
					if (res.code == 200) {
						ElMessage({
							message: res.message || res.msg,
							type: "success",
						});
						this.loadData(this.pageNo); // 拆分
					} else {
						ElMessage.error(res.message || res.msg);
					}
				})
				.finally(() => {
					// options.item._loading_btn[options.index][options.pri] = false;
				});
		},
		// 分解
		listCut(options) {
			this.currentItem = options.item;
			this.$refs.iDialog.open();
			this.$nextTick(() => {
				this.$refs.cutBox.init(options.item.id);
			});
		},
		// 跳转
		logLink(options) {
			let rName = this.$route.name;
			let path = "",
				name = "",
				query = {},
				label = "";
			if (rName == "dwsh") {
				path = "/dwshrz";
				name = "dwshrz";
				query.lmgwnionubi = JSON.stringify({
					taskid: options.item.id,
				});
				label = "待我审核-日志";
			}
			if (rName == "lssh") {
				path = "/lsshrz";
				name = "lsshrz";
				label = "历史审核-日志";
			}
			if (rName == "dwpf") {
				path = "/dwpfrz";
				name = "dwpfrz";
				query.lmgwnionubi = JSON.stringify({
					taskid: options.item.id,
				});
				label = "待我批复-日志";
			}
			if (rName == "lspf") {
				path = "/lspfrz";
				name = "lspfrz";
				label = "历史批复-日志";
			}
			// 权限配置列表 → 部门
			if (rName == "qxpz") {
				path = "/qxbmlb";
				name = "qxbmlb";
				query.lmgwnionubi = JSON.stringify({
					groupid: options.item.id,
				});
				label = "部门";
			}
			// 编制提交 → 部门
			if (rName == "dbz") {
				path = "/dbzbm";
				name = "dbzbm";
				query.lmgwnionubi = JSON.stringify({
					taskid: options.item.id,
				});
				label = "部门";
			}
			// 任务总览 → 审核进度
			if (rName == "wdrw") {
				this.lctLink(options, {
					path: "/rwzlshzt",
					name: "rwzlshzt",
					label: "审核进度",
				});
				return;
			}
			if (!path) {
				ElMessage.error("暂无对应页面");
				return;
			}
			store.commit("selectMenu", {
				path,
				name,
				query,
				label,
			});
			this.$router.push({
				path,
				query,
			});
		},
		lctLink(options, target) {
			let { path, name, label } = target;
			let query = {};
			getAction("ys/task/browse/flowstatus", {
				taskid: options.item.id,
			}).then((res) => {
				if (res.code == 200) {
					query.lmgwnionubi = JSON.stringify({
						data: res.data,
					});
					store.commit("selectMenu", {
						path,
						name,
						query,
						label,
					});
					this.$router.push({
						path,
						query,
					});
				} else {
					ElMessage.error(res.message || res.msg);
				}
			});
		},
		// 跳转
		listDetail(options) {
			let rName = this.$route.name;
			let path = "",
				name = "",
				query = {},
				label = "";
			// 任务总览、拆分、分解、编制提交、退回预算修改 → 明细
			if (rName == "wdrw" || rName == "rwcf" || rName == "cf" || rName == "dbz" || rName == "thysxg") {
				path = "/mx";
				name = "mx";
				query.lmgwnionubi = JSON.stringify({
					taskid: options.item.id,
				});
				query.type = rName;
				label = "预算类型列表";
			}
			// 任务总览、拆分、分解、编制提交、退回预算修改明细 → 条目
			if (rName == "mx") {
				path = "/tm";
				name = "tm";
				query.lmgwnionubi = JSON.stringify(Object.assign({ stylecode: options.item.data[0] }, this.request[options.val].getParams()));
				if (this.$route.query.type) {
					query.type = this.$route.query.type;
				}
				label = "预算条目列表";
			}
			// 权限配置列表 → 用户
			if (rName == "qxpz") {
				path = "/qxyhlb";
				name = "qxyhlb";
				query.lmgwnionubi = JSON.stringify({
					groupid: options.item.id,
				});
				label = "用户";
			}
			// 预算类型样式 → 自定义表单
			if (rName == "yslxpz") {
				path = "/bddy";
				name = "bddy";
				query.lmgwnionubi = JSON.stringify({
					styleid: options.item.id,
				});
				label = "预算类型样式表单定义";
			}
			// 待我审核 → 样式
			if (rName == "dwsh") {
				path = "/dwshmx";
				name = "dwshmx";
				query.lmgwnionubi = JSON.stringify({
					taskid: options.item.id,
				});
				label = "待我审核样式明细";
			}
			// 待我审核样式 → 条目
			if (rName == "dwshmx") {
				path = "/dwshtm";
				name = "dwshtm";
				let styleidx = "";
				this.tableData.forEach((val, key) => {
					if (val.id == options.item.id) {
						styleidx = key;
					}
				});
				query.lmgwnionubi = JSON.stringify(Object.assign({ styleidx }, this.request[options.val].getParams()));
				label = "待我审核条目明细";
			}
			// 历史审核 → 样式
			if (rName == "lssh") {
				path = "/lsshmx";
				name = "lsshmx";
				query.lmgwnionubi = JSON.stringify({
					taskid: options.item.id,
				});
				label = "历史审核样式明细";
			}
			// 历史审核样式 → 条目
			if (rName == "lsshmx") {
				path = "/lsshtm";
				name = "lsshtm";
				let styleidx = "";
				this.tableData.forEach((val, key) => {
					if (val.id == options.item.id) {
						styleidx = key;
					}
				});
				query.lmgwnionubi = JSON.stringify(Object.assign({ styleidx }, this.request[options.val].getParams()));
				label = "历史审核条目明细";
			}
			// 待我批复 → 样式
			if (rName == "dwpf") {
				path = "/dwpfmx";
				name = "dwpfmx";
				query.lmgwnionubi = JSON.stringify({
					taskid: options.item.id,
				});
				label = "待我批复样式明细";
			}
			// 待我批复样式 → 条目
			if (rName == "dwpfmx") {
				path = "/dwpftm";
				name = "dwpftm";
				let styleidx = "";
				this.tableData.forEach((val, key) => {
					if (val.id == options.item.id) {
						styleidx = key;
					}
				});
				query.lmgwnionubi = JSON.stringify(Object.assign({ styleidx }, this.request[options.val].getParams()));
				label = "待我批复条目明细";
			}
			// 历史批复 → 样式
			if (rName == "lspf") {
				path = "/lspfmx";
				name = "lspfmx";
				query.lmgwnionubi = JSON.stringify({
					taskid: options.item.id,
				});
				label = "历史批复样式明细";
			}
			// 历史批复样式 → 条目
			if (rName == "lspfmx") {
				path = "/lspftm";
				name = "lspftm";
				let styleidx = "";
				this.tableData.forEach((val, key) => {
					if (val.id == options.item.id) {
						styleidx = key;
					}
				});
				query.lmgwnionubi = JSON.stringify(Object.assign({ styleidx }, this.request[options.val].getParams()));
				label = "历史批复条目明细";
			}
			if (!path) {
				ElMessage.error("暂无对应页面");
				return;
			}
			store.commit("selectMenu", {
				path,
				name,
				query,
				label,
			});
			this.$router.push({
				path,
				query,
			});
		},
		// str事名 item行据 index列引
		switchBefore(e, options) {
			let params = {
				id: options.item.id,
			};
			this.request[options.str].params.forEach((item) => {
				params[item.key] = options.item.data[item.index] ? "0" : "1";
			});
			// options.item._loading_com[options.index] = true;
			req[this.request[options.str].requestWay](this.request[options.str].url, params)
				.then((res) => {
					if (res.code == 200) {
						ElMessage({
							message: res.message || res.msg,
							type: "success",
						});
						options.item.data[options.index] = e;
						// this.loadData(1); // switch
					} else {
						ElMessage.error(res.message || res.msg);
					}
				})
				.finally(() => {
					// options.item._loading_com[options.index] = false;
				});
		},
		addRowData(options) {
			let { data, totalFlag, selectFlag } = options;
			data._loading_btn = [];
			data._loading_com = [];
			data.data.forEach((val) => {
				if (Array.isArray(val)) {
					let arr = [];
					val.forEach(() => {
						arr.push(false);
					});
					data._loading_btn.push(arr);
				} else {
					data._loading_btn.push("");
				}
			});
			this.head.forEach((val) => {
				if (val.edit) {
					data._loading_com.push(false);
				} else {
					data._loading_com.push("");
				}
			});
			if (totalFlag) {
				this.total += 1;
			}
			if (selectFlag) {
				if (this.tableData.length > 0) {
					data._select = structuredClone(this.tableData[0]._select);
				} else {
					this.loadData(this.pageNo); // 新增
					return;
				}
			}
			this.tableData.unshift(data);
		},
		init(params, tableDataConReq) {
			this.requestWay = this.request.table.requestWay;
			this.url.list = this.request.table.url;
			this.params = params;
			if (tableDataConReq) {
				this.tableDataConReq = tableDataConReq;
			} else {
				this.tableDataConReq = [];
			}
			let boxHeight = 0;
			if (this.$parent.$refs.headSearch) {
				let node = this.$parent.$refs.headSearch.$el;
				let style = window.getComputedStyle(node);
				boxHeight = node.offsetHeight + parseFloat(style.marginTop) + parseFloat(style.marginBottom) + 16;
			} else {
				boxHeight = 55;
			}
			this.cardStyle = { height: `calc(100% -  ${boxHeight}px)` };
			this.loadData(1); // 初始化
		},
		handleData(data) {
			if (this.tableDataConReq.length > 0) {
				if (data.content.length > 0) {
					this.loadDataAgain(data);
					setTimeout(() => {
						this.loading = true;
					}, 0);
				} else {
					this.head = data.title;
					this.tableData = data.content;
				}
			} else {
				this.head = data.title;
				this.tableData = data.content;
			}
		},
		async loadDataAgain(data) {
			for (let i = 0; i < data.content.length; i++) {
				data.content[i]._select = [];
				for (let j = 0; j < data.content[i].data.length; j++) {
					data.content[i]._select.push("");
				}
			}
			for (let i = 0; i < this.tableDataConReq.length; i++) {
				if (this.tableDataConReq[i].params && this.tableDataConReq[i].params.length > 0) {
					for (let j = 0; j < data.content.length; j++) {
						let params = {};
						for (let m = 0; m < this.tableDataConReq[i].params.length; m++) {
							params[this.tableDataConReq[i].params[m].key] = data.content[j][this.tableDataConReq[i].params[m].source];
						}
						let res = await req[this.tableDataConReq[i].method](this.tableDataConReq[i].url, params);
						if (res.code == 200) {
							let arr = [];
							for (let n = 0; n < res.data.length; n++) {
								arr.push({
									label: res.data[n],
									value: res.data[n],
								});
							}
							data.content[j]._select[this.tableDataConReq[i].edit] = arr;
						}
					}
				} else {
					let res = await req[this.tableDataConReq[i].method](this.tableDataConReq[i].url);
					if (res.code == 200) {
						let arr = [];
						for (let n = 0; n < res.data.length; n++) {
							arr.push({
								label: res.data[n],
								value: res.data[n],
							});
						}
						for (let j = 0; j < data.content.length; j++) {
							data.content[j]._select[this.tableDataConReq[i].edit] = arr;
						}
					}
				}
			}
			this.loading = false;
			this.head = data.title;
			this.tableData = data.content;
		},
		getTableData() {
			this.cutLoading = true;
			return JSON.parse(JSON.stringify(this.tableData));
		},
		cutStop() {
			this.cutLoading = false;
		},
		cutOk() {
			this.$refs.iDialog.hide();
			this.loadData(this.pageNo); // 分解
		},
		search(params) {
			this.params = params;
			this.loadData(this.pageNo); // 搜索
		},
		handleSelectionChange(e) {
			this.$emit("checkboxChange", e);
		},
	},
};
</script>
<style lang="less" scoped>
.bot {
	margin: 20px 0 0;
	display: flex;
	justify-content: flex-end;
}
</style>

<template>
	<el-dialog v-model="visible" :show-close="false" :close-on-click-modal="!loading" :close-on-press-escape="!loading" @close="close">
		<template #header="{ close, titleId }">
			<div class="my-header">
				<h4 :id="titleId">{{ title }}</h4>
				<el-icon style="cursor: pointer" @click="loading ? () => {} : close()"><Close /></el-icon>
			</div>
		</template>
		<slot></slot>
		<template #footer></template>
	</el-dialog>
</template>

<script>
export default {
	name: "iDialog",
	props: {
		title: {
			type: String,
			default: "弹框标题",
		},
		loading: {
			tyoe: Boolean,
			default: false,
		},
	},
	data() {
		return {
			visible: false,
		};
	},
	methods: {
		open() {
			this.visible = true;
		},
		hide() {
			this.visible = false;
		},
		close() {
			this.$emit("cancel");
		},
	},
};
</script>

<style>
.my-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
</style>

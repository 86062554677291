<template>
	<el-aside :width="$store.state.isCollapse ? '250px' : '64px'">
		<h3 style="background-color: #0494f5; width: 249px" v-show="$store.state.isCollapse">基础框架</h3>
		<h3 style="background-color: #0494f5; width: 63px" v-show="!$store.state.isCollapse">基础</h3>
		<el-menu
			class="nemuLose"
			background-color="#0494f5"
			text-color="#fff"
			:collapse="!$store.state.isCollapse"
			:collapse-transition="false"
			:unique-opened="true"
			:default-active="temp"
		>
			<el-menu-item v-for="item in shouye()" :key="item.id" :index="item.path" @click="clickMenu(item)">
				<el-icon>
					<component class="icons" v-if="item.icon" :is="item.icon"></component>
				</el-icon>
				<span>{{ item.label }}</span>
			</el-menu-item>
			<el-sub-menu v-for="item in hasBumen()" :key="item.id" :index="item.path">
				<template #title>
					<el-icon>
						<component class="icons" v-if="item.icon" :is="item.icon"></component>
					</el-icon>
					<span>{{ item.label }}</span>
				</template>
				<el-menu-item v-for="subItem in item.children" :key="subItem.id" :index="subItem.path" @click="clickMenu(subItem)">
					<el-icon>
						<component class="icons" v-if="subItem.icon" :is="subItem.icon"></component>
					</el-icon>
					<span>{{ subItem.label }}</span>
				</el-menu-item>
			</el-sub-menu>
			<el-menu-item v-for="item in noChilden()" :key="item.id" :index="item.path" @click="clickMenu(item)">
				<el-icon>
					<component class="icons" v-if="item.icon" :is="item.icon"></component>
				</el-icon>
				<span>{{ item.label }}</span>
			</el-menu-item>
			<el-sub-menu v-for="item in hasChilden()" :key="item.id" :index="item.path">
				<template #title>
					<el-icon>
						<component class="icons" v-if="item.icon" :is="item.icon"></component>
					</el-icon>
					<span>{{ item.label }}</span>
				</template>
				<el-menu-item v-for="subItem in item.children" :key="subItem.id" :index="subItem.path" @click="clickMenu(subItem)">
					<el-icon>
						<component class="icons" v-if="subItem.icon" :is="subItem.icon"></component>
					</el-icon>
					<span>{{ subItem.label }}</span>
				</el-menu-item>
			</el-sub-menu>
		</el-menu>
	</el-aside>
</template>
<script>
import router from "@/router";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
export default {
	setup() {
		const store = useStore();
		const route = useRoute();
		const temp = computed(() => {
			return route.path;
		});
		const noChilden = () => {
			return store.state.menuList.filter((item) => !item.children && item.label !== "首页");
		};
		const hasChilden = () => {
			return store.state.menuList.filter((item) => item.children && item.id !== "1");
		};
		const hasBumen = () => {
			return store.state.menuList.filter((item) => item.id === "1");
		};
		const shouye = () => {
			return store.state.menuList.filter((item) => item.label === "首页");
		};
		const clickMenu = (item) => {
			if (item.name == route.name) {
				return;
			}
			store.commit("selectMenu", item);
			router.push({
				path: item.path,
			});
		};
		return {
			noChilden,
			hasChilden,
			clickMenu,
			hasBumen,
			temp,
			shouye,
		};
	},
};
</script>
<style lang="less" scoped>
* {
	user-select: none;
}
.icons {
	width: 18px;
	height: 18px;
}
.nemuLose {
	min-height: calc(100vh - 48px);
}
h3 {
	line-height: 48px;
	color: #fff;
	text-align: center;
}
/deep/ .el-menu > .el-menu-item.is-active {
	// 一级菜单点击样式
	color: #fff;
	background-color: #035f9c;
}
/deep/ .el-menu--inline > .el-menu-item.is-active {
	// 二级菜单点击样式
	color: #fff;
	background-color: #035f9c;
}
/deep/ .el-menu--inline {
	background-color: #0382d6; // 展开背景颜色
}
</style>
